
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























































































.job-listing {
  margin-top: 0;
  padding-top: 1rem;

  @include mq(l) {
    padding-top: 4rem;
  }
}

.job-listing-wrapper {
  padding-top: 3rem;
  border-top: 1px solid $c-light-grey;

  @include mq(l) {
    padding-top: 6rem;
  }
}

.job-listing__list {
  @extend %list-nostyle;
}

.job-listing__item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.5rem;
  border-top: 1px solid $c-light-grey;

  &:last-child {
    border-bottom: 1px solid $c-light-grey;
  }

  @include mq(s) {
    flex-wrap: nowrap;
    align-items: center;
  }
}

.job-listing__item__title,
.job-listing__item__location {
  @include mq(s) {
    flex-basis: col(4, 10);
    max-width: none;
    margin-bottom: 0;
  }
}

.job-listing__item__title {
  transition: color 0.2s $ease-softer;

  .job-listing__item:hover & {
    color: $c-mustard;
  }

  @include mq($until: s) {
    flex-basis: 100%;
  }
}

.job-listing__item__location {
  display: flex;
  align-items: flex-start;
  max-width: 50%;

  .icon {
    flex-shrink: 0;
    margin-right: 1rem;
  }
}

.job-listing__item__link {
  text-align: right;

  @include mq(s) {
    width: col(2, 10);
  }
}

.job-listing__item__link__action {
  &::after {
    @include get-all-space;

    content: '';
  }
}
