
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































.jobs__heading__title,
.jobs__heading__subtitle,
.jobs__heading__intro {
  margin-bottom: $spacing;
}

.jobs__sticky__btn {
  position: fixed;
  z-index: 99;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  margin: $spacing;
  padding: 1rem;
  color: $c-mustard;
  background-color: $c-white;
  border: 0;
  border-radius: 0.5rem;
  cursor: pointer;
  // box-shadow: 0 50px 100px rgba($c-black, 0.15);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.25);

  &.fade-enter-active,
  &.fade-leave-active {
    transition: all 0.5s $ease-softer;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
    transform: translateY(calc(100% + 4rem));
  }

  @include mq(l) {
    right: $spacing * 2;
    bottom: $spacing * 2;
    margin: 0;
  }
}

.jobs__sticky__btn__label {
  @extend %ff-alt;
  @extend %fw-medium;

  margin: 0 $spacing 0 $spacing * 0.5;
}

.jobs__sticky__btn__arrow {
  transform: rotate(90deg);
  transition: transform 0.2s ease-out;

  .jobs__sticky__btn:hover &,
  .jobs__sticky__btn:focus-visible {
    transform: translateY(0.5rem) rotate(90deg);
  }
}

::v-deep .cpt-blog .wysiwyg__content {
  @include fluid(
    padding-bottom,
    (
      xxs: 60px,
      m: 80px,
      xl: 100px,
    )
  );

  border-bottom: 1px solid $c-light-grey;
}

.jobs__content {
  ::v-deep .flexible-quote-container {
    margin-bottom: 0;
    border: 0;
  }
}

.jobs__content__flexible {
  ::v-deep > *:last-child {
    margin-bottom: 0;
  }
}
